import React, { useState } from 'react';
import logo from '../src/img/flexisoc.png'; // Importe a imagem

function App() {
  const [pid, setPid] = useState(null);

  const handleRunScript = async () => {
    try {
      const response = await fetch('http://192.168.90.6:3002/run-script', {
        method: 'POST'
      });
      if (response.ok) {
        alert('Script iniciado com sucesso!');
      } else {
        alert('Falha ao iniciar o script');
      }
    } catch (error) {
      console.error('Erro:', error);
      alert('Erro ao iniciar o script');
    }
  };

  const handleGetPid = async () => {
    try {
      const response = await fetch('http://192.168.90.6:3002/get-pid');
      if (response.ok) {
        const data = await response.json();
        setPid(data.pid);
        alert(`PID do script: ${data.pid}`);
      } else {
        alert('Nenhum script em execução');
      }
    } catch (error) {
      console.error('Erro:', error);
      alert('Erro ao obter o PID');
    }
  };

  const handleKillScript = async () => {
    try {
      const response = await fetch('http://192.168.90.6:3002/kill-script', {
        method: 'POST'
      });
      if (response.ok) {
        alert('Script terminado com sucesso!');
        setPid(null);
      } else {
        alert('Falha ao terminar o script');
      }
    } catch (error) {
      console.error('Erro:', error);
      alert('Erro ao terminar o script');
    }
  };

  return (
    <div className="App">
      <img src={logo} alt="Logo" />
      <header className="App-header">
        <h1>Out Of Office</h1>
        <button onClick={handleRunScript}>Executar Script</button>
        <button onClick={handleGetPid}>Verificar Processo</button>
        {pid && <button onClick={handleKillScript}>Terminar Processo</button>}
      </header>
    </div>
  );
}

export default App;
